import { Component, OnInit } from '@angular/core';
import {NbDialogRef} from '@nebular/theme';

@Component({
  selector: 'ngx-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
})
export class ImagePreviewComponent implements OnInit {
  imageSrc: string = null;
  file: any;
  constructor(protected ref: NbDialogRef<ImagePreviewComponent>) { }

  ngOnInit() {
  }
  readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
        this.file = event.target.files[0];

        const reader = new FileReader();
        reader.onload = e => this.imageSrc = reader.result.toString();

        reader.readAsDataURL(this.file);
    }
  }
  cancel() {
    this.ref.close();
  }

  submit() {
    const respon = [this.file, this.imageSrc];
    this.ref.close(respon);
  }

}
