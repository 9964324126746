import { Injectable } from '@angular/core';
import {Admin} from '../models/admin';

@Injectable()
export class MeService {
  admin?: any = null;
  token?: string = null;
  role?: number = null;
  partner_type?: number = null;

  constructor() { }

  setToken(token: string): void {
    this.token = token;
  }

  setAdmin(admin: Admin): Admin {
    this.admin = admin;
    return admin;
  }

  setRole(role: number): void {
    this.role = role;
  }
  getAdmin(): Admin {
    return this.admin;
  }

  setPartnerType(type: number): void {
    this.partner_type = type;
  }

  forget(): void {
    this.admin = null;
    this.token = null;
  }

}
