import * as io from 'socket.io-client';
import { BehaviorSubject } from 'rxjs';

import {environment} from '../../environments/environment';

export class SocketService {
  private url = environment.socket_server + environment.namespace;
  private socket;
  private nearbyWorkers = new BehaviorSubject({});
  worker_status = this.nearbyWorkers.asObservable();

  constructor() {}

  public init_socket() {
    this.socket = io(this.url);
    this.socket.on('connect', function () {
    });
    this.socket.on('disconnect', function () {
    });
    this.socket.on('error', function (err) {
    });
    this.socket.on('nearbyWorkers', (message) => {
      this.nearbyWorkers.next(message);
    });
    this.getNearbyWorkers();
  }

  public getNearbyWorkers() {
    const message = {
      rd: 180,
      la: 0,
      lo: 0,
    };
    setInterval(() => {
      this.socket.emit('getNearbyWorkers', message);
    }, 5000);
  }
}
