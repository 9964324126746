import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { AuthData } from '../models/auth-data';
import compress from 'compressorjs'; // Import a compression library

@Injectable()
export class ApiService {
  authToken = {
    post: (auth: AuthData) => this.request
      .post()
      .url('admins/auth/token')
      .payload(auth),
  };
  me_admin = {
    get: () => this.request
      .get()
      .url('admins/me')
      .auth(),
  };
  drivers = {
    get: () => this.request
      .get()
      .url('frontend/workers')
      .auth(),
    getInPartner: (id) => this.request
      .get()
      .url(`partner/${id}/workers`)
      .auth(),
    getById: (id) => this.request
      .get()
      .url(`frontend/workers/${id}`)
      .auth(),
    delete: (id) => this.request
      .delete()
      .url(`frontend/workers/${id}`)
      .auth(),
  };
  document = {
    update: (driver_license, id) => this.request
      .post()
      .url('frontend/document/update/{}', id)
      .payload(driver_license)
      .auth(),
  };
  clients = {
    get: () => this.request
      .get()
      .url('frontend/users')
      .auth(),
    delete: (id) => this.request
      .delete()
      .url(`frontend/users/${id}`)
      .auth(),
  };
  concierges = {
    get: (code_type) => this.request
      .get()
      .url(`admin/auth/affiliate/${code_type}`)
      .auth(),
    put: (obj) => this.request
      .post()
      .url(`frontend/concierges/update/${obj.id}`)
      .payload(obj)
      .auth(),
  };
  affiliates_code = {
    get: () => this.request
      .get()
      .url('admin/auth/affiliate/get/code')
      .auth(),
    create: (invite_code) => this.request
      .post()
      .url('admin/auth/affiliate/create/code')
      .payload(invite_code)
      .auth(),
    getAvailableUser: (code_type) => this.request
      .get()
      .url(`admin/auth/affiliate/available/${code_type}`)
      .auth(),
    assignCode: (code_id, obj) => this.request
      .post()
      .url(`admin/auth/affiliate/code/${code_id}`)
      .payload(obj)
      .auth(),
  };
  partners = {
    get: () => this.request
      .get()
      .url('frontend/partners')
      .auth(),
    put: (obj) => this.request
      .post()
      .url(`partners/update/${obj.id}`)
      .payload(obj)
      .auth(),
  };
  vehicle = {
    get: () => this.request
      .get()
      .url('admin/vehicles')
      .auth(),
    getInPartner: (id) => this.request
      .get()
      .url(`admin/${id}/vehicles`)
      .auth(),
    getWorkerVehicles: (worker_id) => this.request
      .get()
      .url(`admin/worker/${worker_id}/vehicles`)
      .auth(),
    getByID: (id) => this.request
      .get()
      .url(`admin/vehicles/${id}`)
      .auth(),
    put: (obj) => this.request
      .post()
      .url(`admin/vehicles/update/${obj.id}`)
      .payload(obj)
      .auth(),
    post: (vehicle: any) => this.request
      .post()
      .url('admin/vehicle/add')
      .file(vehicle)
      .auth(),
  };
  ridetype = {
    get: () => this.request
      .get()
      .url('frontend/type/ride')
      .auth(),
    updateWithoutImage: (ride_type, id) => this.request
      .post()
      .url('frontend/type/ride/update/{}', id)
      .payload(ride_type)
      .auth(),
  };
  make = {
    get: (id) => this.request
      .get()
      .url(`frontend/type/${id}/make`)
      .auth(),
    post: (obj) => this.request
      .post()
      .url('frontend/type/make/create')
      .payload(obj)
      .auth(),
    update: (make, id) => this.request
      .post()
      .url('frontend/type/make/update/{}', id)
      .payload(make)
      .auth(),
    delete: (id) => this.request
      .delete()
      .url('frontend/type/make/{}', id)
      .auth(),
  };
  model = {
    get: (make_id) => this.request
      .get()
      .url(`frontend/type/${make_id}/model`)
      .auth(),
    post: (obj) => this.request
      .post()
      .url('frontend/type/model/create')
      .payload(obj)
      .auth(),
    update: (model, id) => this.request
      .post()
      .url('frontend/type/model/update/{}', id)
      .payload(model)
      .auth(),
    delete: (id) => this.request
      .delete()
      .url('frontend/type/model/{}', id)
      .auth(),
  };
  car = {
    get: (model_id) => this.request
      .get()
      .url(`frontend/model/${model_id}/car`)
      .auth(),
    post: (car: any) => this.request
      .post()
      .url('frontend/car/create')
      .file(car)
      .auth(),
    update: (car: any, id) => this.request
      .post()
      .url('frontend/car/update/file/{}', id)
      .file(car)
      .auth(),
    updateWithoutImage: (car, id) => this.request
      .post()
      .url('frontend/car/update/{}', id)
      .payload(car)
      .auth(),
    deleteCar: (id) => this.request
      .delete()
      .url('frontend/car/{}', id)
      .auth(),
    getMobileCarFilter: () => this.request
      .get()
      .url('admin/car/filter'),
  };
  booking = {
    get: () => this.request
      .get()
      .url('frontend/book/ride')
      .auth(),
    getById: (id) => this.request
      .get()
      .url(`frontend/book/${id}`)
      .auth(),
    getSummary: () => this.request
      .get()
      .url('frontend/book/ride/summary')
      .auth(),
    getDailySummary: () => this.request
      .get()
      .url('frontend/book/ride/daily-summary')
      .auth(),
  };
  vehicle_invite = {
    get: () => this.request
      .get()
      .url('partner/vehicle-inviter')
      .auth(),
    post: (obj) => this.request
      .post()
      .url('partner/vehicle-invite')
      .payload(obj)
      .auth(),
  };
  partner_bank = {
    get: () => this.request
      .get()
      .url('partner/banks')
      .auth(),
    post: (obj) => this.request
      .post()
      .url('partner/create-bank')
      .payload(obj)
      .auth(),
    set_default: (obj) => this.request
      .put()
      .url('partner/default-bank')
      .payload(obj)
      .auth(),
    deleteBank: (obj) => this.request
      .post()
      .url('partner/delete-bank')
      .payload(obj)
      .auth(),
  };
  // map = {
  //   get: (req) => this.request
  //     .post()
  //     .url('front/booking/getNearbyWorkers')
  //     .payload(req)
  //     .auth(),
  // };
  map = {
    get: (req) => this.request
      .post()
      .url('front/booking/onlineWorkers')
      .payload(req)
      .auth(),
  };
  promo_code = {
    get: () => this.request
      .get()
      .url('web/promocodes')
      .auth(),
    generate: () => this.request
      .get()
      .url('web/promocodes/generate')
      .auth(),
    create: (promo_code) => this.request
      .post()
      .url('web/promocodes/create')
      .payload(promo_code)
      .auth(),
    update: (promo_code, id) => this.request
      .put()
      .url('promocodes/update/{}', id)
      .payload(promo_code)
      .auth(),
    delete: (id) => this.request
      .delete()
      .url('promocodes/{}', id)
      .auth(),
  };
  invite = {
    get: () => this.request
      .get()
      .url('admin/get-inviter')
      .auth(),
    post: (obj) => this.request
      .post()
      .url('admin/mail-invite')
      .payload(obj)
      .auth(),
  };
  profit_code = {
    get: () => this.request
      .get()
      .url('admin/auth/affiliate/code')
      .auth(),
    // create: (invite_code) => this.request
    //   .post()
    //   .url('admin/auth/affiliate/code')
    //   .payload(invite_code)
    //   .auth(),
    // getAvailableUser: (code_type) => this.request
    //   .get()
    //   .url(`admin/auth/affiliate/available/${code_type}`)
    //   .auth(),
    // assignCode: (code_id, obj) => this.request
    //   .post()
    //   .url(`admin/auth/affiliate/code/${code_id}`)
    //   .payload(obj)
    //   .auth(),
  };
  profit = {
    get: () => this.request
      .get()
      .url('admin/book/ride')
      .auth(),
    getInvites: () => this.request
      .get()
      .url('admin/invite')
      .auth(),
  };
  transaction = {
    get: () => this.request
      .get()
      .url('admin/transaction')
      .auth(),
    post: (obj) => this.request
      .post()
      .url('admin/refund/transaction')
      .payload(obj)
      .auth(),
    getRefundedList: () => this.request
      .get()
      .url('admin/transaction/refund_list')
      .auth(),
  };
  constructor(private request: RequestService) {
  }

}
